import {Controller} from "@hotwired/stimulus"
import {Toast} from "bootstrap"

export class BootstrapTurboController extends Controller {
    connect() {
        this.toast = new Toast(this.element)
        this.toast.show()
    }

    disconnect() {
        this.element.remove()
    }
}