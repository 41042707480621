import {Controller} from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap';

export default class extends Controller {
    static targets = ["email", "additionalFields"]

    connect() {
        this.bsCollapse = new bootstrap.Collapse(this.additionalFieldsTarget, {toggle: false})
        this.emailTarget.addEventListener('input', this.checkVisibility);
        this.checkVisibility();
    }

    disconnect() {
        this.emailTarget.removeEventListener('input', this.checkVisibility);
    }

    checkVisibility = () => {
        if (this.emailIsFilledOut()) {
            this.bsCollapse.show()
        } else {
            this.bsCollapse.hide()
        }
    }

    emailIsFilledOut() {
        return this.emailTarget.value && this.emailTarget.value.length >= 3
    }
}