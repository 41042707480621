import {Controller} from "@hotwired/stimulus"
import {FetchRequest} from '@rails/request.js'

export class NotificationsController extends Controller {
    static values = {url: String}
    static targets = ["badge", "notification"]

    connect() {
        this.element.addEventListener("shown.bs.dropdown", this.addTimeout)
        this.element.addEventListener("hidden.bs.dropdown", this.removeTimeout)
    }

    disconnect() {
        this.element.removeEventListener("shown.bs.dropdown", this.addTimeout)
        this.element.removeEventListener("hidden.bs.dropdown", this.removeTimeout)
    }

    addTimeout = () => {
        this.timeout = setTimeout(this.markAllAsRead, 2000)
    }

    removeTimeout = () => {
        clearTimeout(this.timeout)
    }

    markAllAsRead = async () => {
        const request = new FetchRequest("patch", this.urlValue, {responseKind: 'json'})
        const response = await request.perform()
        if (response.ok) {
            if (this.hasBadgeTarget) {
                this.badgeTarget.classList.add("d-none")
            }
            this.notificationTargets.forEach((e) => {
                e.classList.add("text-muted")
                Array.from(e.getElementsByClassName("notification-title")).forEach((title) => {
                    title.classList.remove("fw-bold")
                })
            })
        }
    }
}