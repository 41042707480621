// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import {StreamActions} from "@hotwired/turbo"
import * as bootstrap from "bootstrap"

import "./controllers"
import "./font_awesome"

StreamActions.redirect = function () {
    Turbo.visit(this.getAttribute("target"))
}
