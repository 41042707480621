import {Controller} from "@hotwired/stimulus"

export class UseFormController extends Controller {
    static targets = ["companyFields"]

    toggle(e) {
        if (e.target.value === "company") {
            this.companyFieldsTarget.classList.remove('d-none')
        } else {
            this.companyFieldsTarget.classList.add('d-none')
        }
    }
}