import {Controller} from "@hotwired/stimulus"
import {BarController, BarElement, CategoryScale, Chart, LinearScale, Tooltip} from 'chart.js';

Chart.register(
    BarElement,
    BarController,
    CategoryScale,
    LinearScale,
    Tooltip,
);

export default class extends Controller {
    static values = {
        ratio: Number,
        data: Array
    }

    get ratio() {
        if (this.hasRatioValue) {
            return this.ratioValue
        } else {
            return 3
        }
    }

    connect() {
        this.chart = new Chart(this.element.getContext('2d'), {
            type: 'bar',
            data: {
                datasets: [{
                    data: this.dataValue,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                aspectRatio: this.ratio,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value, index, values) {
                                return '€' + value;
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = ''
                                if (context.parsed.y !== null) {
                                    label = new Intl.NumberFormat('de-AT', {style: 'currency', currency: 'EUR'}).format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                }
            }
        })
    }

    disconnect() {
        this.chart.destroy()
        this.chart = undefined
    }
}