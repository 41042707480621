import {Controller} from "@hotwired/stimulus"
import IMask from 'imask';
import moment from 'moment';

export class BirthdayInputController extends Controller {
    connect() {
        let momentFormat = 'DD.MM.YYYY';
        this.mask = IMask(this.element, {
            mask: Date,
            pattern: momentFormat,
            lazy: false,
            autofix: true,
            min: new Date(1900, 0, 1),
            max: new Date(2030, 0, 1),

            format: function (date) {
                return moment(date).format(momentFormat);
            },
            parse: function (str) {
                return moment(str, momentFormat);
            },

            blocks: {
                YYYY: {
                    placeholderChar: 'Y',
                    mask: IMask.MaskedRange,
                    from: 1900,
                    to: 2030
                },
                MM: {
                    placeholderChar: 'M',
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12
                },
                DD: {
                    placeholderChar: 'D',
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31
                }
            }
        });
    }

    disconnect() {
        this.mask.destroy()
    }
}